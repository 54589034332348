/* tslint:disable */
/* eslint-disable */
export enum MembershipStatusDto {
  Paused = 'PAUSED',
  Active = 'ACTIVE',
  PaymentFailed = 'PAYMENT_FAILED',
  PendingCancellation = 'PENDING_CANCELLATION',
  Cancelled = 'CANCELLED',
  PastDue = 'PAST_DUE'
}
