import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public eventEmitter(
    eventName: string,
    target: string) {
    gtag('event', eventName, {
      'send_to': target,
      'transaction_id': ''
    })
  }

}
