import {computed, effect, Injectable, signal} from '@angular/core';
import {FeatureFlagApi} from '../../../api/services/feature-flag-api';
import {FeatureFlagNameDto, GetFeatureFlagDto, ListFeatureFlagDto, UserDto} from '../../../api/models';
import {firstValueFrom, Observable, of} from 'rxjs';
import {UserService} from '../../../main-layout/services/user/user.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {toObservable, toSignal} from "@angular/core/rxjs-interop";

interface GetFlagPayload {
  name: string;
}

interface FeatureFlag {
  name: FeatureFlagNameDto;
  isEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  flags$ = toObservable(this.userService.state.user)
    .pipe(
      switchMap((user) => this.fetchFlags())
    );
  private readonly state = {
    // flags: toSignal(this.flags$),
    flags: signal<FeatureFlag[]>([]),
  } as const;

  public readonly flags = this.state.flags.asReadonly();
  public readonly usePaddleBilling = computed(
    () => !!this.flags().find(flag => flag.name === FeatureFlagNameDto.UsePaddleBilling)?.isEnabled);

  constructor(
    private readonly featureFlagApi: FeatureFlagApi,
    private readonly userService: UserService,
  ) {
    // effect(() => {
    //   const user = userService.state.user();
    //   this.fetchFlags().subscribe(({ flags }) => {
    //     this.state.flags.set(flags);
    //   });
    // });
  }

  async fetchFlags(): Promise<void> {
    // return this.featureFlagApi.featureFlagControllerListFlags();
    const { flags } = await firstValueFrom(
      this.featureFlagApi.featureFlagControllerListFlags()
    );
    this.state.flags.set(flags);
  }

  private getFlag({ name }: GetFlagPayload): Observable<GetFeatureFlagDto> {
    return this.featureFlagApi.featureFlagControllerGetFlag({ name });
  }

  shouldUseMembershipV2(): Observable<boolean> {
    if (!this.userService.userSnapshot) {
      return of(true);
    }
    return this.getFlag({ name: FeatureFlagNameDto.UsePaddleBilling })
      .pipe(
        map(({ isEnabled }) => isEnabled),
        catchError(() => of(false)),
      );
  }
}
