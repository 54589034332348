/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetFeatureFlagDto } from '../models/get-feature-flag-dto';
import { ListFeatureFlagDto } from '../models/list-feature-flag-dto';

@Injectable({ providedIn: 'root' })
export class FeatureFlagApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `featureFlagControllerListFlags()` */
  static readonly FeatureFlagControllerListFlagsPath = '/api/feature-flag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureFlagControllerListFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureFlagControllerListFlags$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListFeatureFlagDto>> {
    const rb = new RequestBuilder(this.rootUrl, FeatureFlagApi.FeatureFlagControllerListFlagsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListFeatureFlagDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureFlagControllerListFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureFlagControllerListFlags(
    params?: {
    },
    context?: HttpContext
  ): Observable<ListFeatureFlagDto> {
    return this.featureFlagControllerListFlags$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListFeatureFlagDto>): ListFeatureFlagDto => r.body)
    );
  }

  /** Path part for operation `featureFlagControllerGetFlag()` */
  static readonly FeatureFlagControllerGetFlagPath = '/api/feature-flag/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `featureFlagControllerGetFlag()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureFlagControllerGetFlag$Response(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetFeatureFlagDto>> {
    const rb = new RequestBuilder(this.rootUrl, FeatureFlagApi.FeatureFlagControllerGetFlagPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFeatureFlagDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `featureFlagControllerGetFlag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  featureFlagControllerGetFlag(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<GetFeatureFlagDto> {
    return this.featureFlagControllerGetFlag$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetFeatureFlagDto>): GetFeatureFlagDto => r.body)
    );
  }

}
