/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiApi } from './services/api-api';
import { AuthApi } from './services/auth-api';
import { OauthApi } from './services/oauth-api';
import { UserApi } from './services/user-api';
import { FeatureFlagApi } from './services/feature-flag-api';
import { SubscriptionApi } from './services/subscription-api';
import { SubscriptionPlanApi } from './services/subscription-plan-api';
import { WebsiteApi } from './services/website-api';
import { ThemeApi } from './services/theme-api';
import { PodcastApi } from './services/podcast-api';
import { EpisodeApi } from './services/episode-api';
import { ReviewApi } from './services/review-api';
import { WebsitePodcastApi } from './services/website-podcast-api';
import { WebsiteEpisodeApi } from './services/website-episode-api';
import { PageApi } from './services/page-api';
import { PostApi } from './services/post-api';
import { AnalyticsApi } from './services/analytics-api';
import { WebsiteUserApi } from './services/website-user-api';
import { WebsiteMembershipPlanApi } from './services/website-membership-plan-api';
import { WebsiteMembershipApi } from './services/website-membership-api';
import { WebsitePublicMembershipApi } from './services/website-public-membership-api';
import { WebsiteContentApi } from './services/website-content-api';
import { AdminApi } from './services/admin-api';
import { AffiliateApi } from './services/affiliate-api';
import { ReferralApi } from './services/referral-api';
import { MembershipApi } from './services/membership-api';
import { MembershipPlanApi } from './services/membership-plan-api';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiApi,
    AuthApi,
    OauthApi,
    UserApi,
    FeatureFlagApi,
    SubscriptionApi,
    SubscriptionPlanApi,
    WebsiteApi,
    ThemeApi,
    PodcastApi,
    EpisodeApi,
    ReviewApi,
    WebsitePodcastApi,
    WebsiteEpisodeApi,
    PageApi,
    PostApi,
    AnalyticsApi,
    WebsiteUserApi,
    WebsiteMembershipPlanApi,
    WebsiteMembershipApi,
    WebsitePublicMembershipApi,
    WebsiteContentApi,
    AdminApi,
    AffiliateApi,
    ReferralApi,
    MembershipApi,
    MembershipPlanApi,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
