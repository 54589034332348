import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {GoogleAnalyticsService} from './google-analytics.service';
import {initializePaddle, Paddle, CheckoutEventNames, getPaddleInstance} from '@paddle/paddle-js';

declare var rdt: Function;
declare var plausible: Function;

interface PaddleCheckoutItem {
  priceId: string;
  quantity: number;
}

interface PaddleCheckoutCustomData extends Record<string, unknown>{
  userId: string;
  planId: string;
  paymentOptionId: string;
}

interface CheckoutPayload {
  isInitialCheckout?: boolean;
  userEmail: string;
  customData: PaddleCheckoutCustomData;
  items: PaddleCheckoutItem[];
  customerId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaddleBillingService {
  private paddle: Paddle;

  constructor(
    private gaService: GoogleAnalyticsService) {}

  async initialize() {
    this.paddle = await initializePaddle({
      debug: false,
      token: environment.paddleBillingToken,
      environment: environment.isPaddleSandbox ? 'sandbox' : 'production',
      checkout: {
        settings: { 
          successUrl: environment.dashboardUrl + environment.paddleSuccessRoute
        }
      },
      eventCallback: ({ data, name }) => {
        if (name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          const { userId } = data.custom_data as PaddleCheckoutCustomData;
          (window as any).fpr('referral', { email: data.customer.email, uid: data.customer.id });
          this.gaService.eventEmitter('conversion', 'AW-477285266/NszrCNCzhrUYEJKXy-MB');
          plausible('Upgrade');
          rdt('track', 'Purchase');
        }
      }
    });
    this.paddle = getPaddleInstance('v1');
  }

  async checkout({ items, userEmail, customerId, customData, isInitialCheckout }: CheckoutPayload) {
    this.paddle.Checkout.open({
      items,
      customer: customerId ? { id: customerId } : { email: userEmail },
      customData,
      settings: isInitialCheckout ? { variant: 'one-page', } : {}
    });
  }

  async updatePaymentMethod(transactionId: string) {
    this.paddle.Checkout.open({
      transactionId
    })
  }

}
