import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubscriptionApi, SubscriptionPlanApi } from '../../../api/services';
import { tap, map } from 'rxjs/operators';
import {
  SubscriptionPaymentDto,
  AddSubscriptionAddonDto,
  SubscriptionAddonDto,
  SubscriptionPlanAddonDto,
  SubscriptionDto
} from '../../../api/models';
import { SubscriptionPlan } from '../../../shared/models/subscription-plan.model';
// import {BaseMembershipService} from "../../../shared/services/membership-plans/membership.service";
import { PaddleService } from "../../../signup/services/paddle.service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private plans: BehaviorSubject<SubscriptionPlan[]> = new BehaviorSubject([]);
  private subscription: BehaviorSubject<SubscriptionDto> = new BehaviorSubject(null);
  private payments: BehaviorSubject<SubscriptionPaymentDto[]> = new BehaviorSubject([]);

  susbcription$ = this.subscription.asObservable();
  payments$ = this.payments.asObservable();
  plans$ = this.plans.asObservable();
  monthlyPlans$ = this.plans$.pipe(map(plans => plans.filter(p => p.durationType === 'month')))
  annualyPlans$ = this.plans$.pipe(map(plans => plans.filter(p => p.durationType === 'year')))

  constructor(
    private subscriptionApi: SubscriptionApi,
    private subscriptionPlanApi: SubscriptionPlanApi,
    private readonly paddle: PaddleService,
  ) { }

  async initializePaddle(): Promise<void> {
    await this.paddle.initialize();
  }

  fetchSubscription() {
    return this.subscriptionApi.subscriptionControllerGetSubscription().pipe(
      tap(subscription => this.subscription.next(subscription))
    )
  }

  cancelSubscription() {
    return this.subscriptionApi.subscriptionControllerCancelSubscription();
  }

  changePlan(planId: string) {
    return this.subscriptionApi.subscriptionControllerChangeSubscriptionPlan({
      planId,
    })
  }

  fetchPayments() {
    return this.subscriptionApi.subscriptionControllerGetSubscriptionPayments().pipe(
      tap(payments => this.payments.next(payments?.map(p => Object.assign(p, { payoutDate: new Date(p.payoutDate)}))))
    )
  }

  addSubscriptionAddon(body: AddSubscriptionAddonDto): Observable<void> {
    return this.subscriptionApi.subscriptionControllerAddSubscriptionAddon({
      body
    })
  }

  listAddons(): Observable<SubscriptionAddonDto[]> {
    return this.subscriptionApi.subscriptionControllerListAddons().pipe()
  }

  removeSubscriptionAddon(addonId: string): Observable<void> {
    return this.subscriptionApi.subscriptionControllerRemoveSubscriptionAddon({
      addonId
    })
  }

  switchAddon(addonId: string, selectedAddonId: string): Observable<void> {
    return this.subscriptionApi.subscriptionControllerSwitchAddon({
      addonId,
      body: {
        planAddonId: selectedAddonId
      }
    })
  }

  // PLANS
  fetchPlans(): Observable<SubscriptionPlan[]> {
    return this.subscriptionPlanApi.subscriptionPlanControllerGetPlans().pipe(
      map(plans => plans.map(plan => SubscriptionPlan.fromApi(plan))),
      map(plans => this.sortPlansByPrice(plans)),
      tap(plans => this.plans.next(plans))
    )
  }

  listAddonPlans(): Observable<SubscriptionPlanAddonDto[]> {
    return this.subscriptionPlanApi.subscriptionPlanControllerListAddons().pipe(
      map(plans => plans?.sort((a, b) => a.index - b.index))
    );
  }

  private sortPlansByPrice(plans: SubscriptionPlan[]) {
    return ([].concat(plans) as SubscriptionPlan[])
      .sort((a, b) => Math.sign(a.recurringPrice - b.recurringPrice))
  }
}
