/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateMembershipCouponDto } from '../models/create-membership-coupon-dto';
import { CreateMembershipPlanAddonsDto } from '../models/create-membership-plan-addons-dto';
import { MembershipCouponDto } from '../models/membership-coupon-dto';
import { MembershipPlanAddonDto } from '../models/membership-plan-addon-dto';
import { MembershipPlanDto } from '../models/membership-plan-dto';
import { UpdateMembershipPlanAddonDto } from '../models/update-membership-plan-addon-dto';

@Injectable({ providedIn: 'root' })
export class MembershipPlanApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `membershipPlanControllerListPublicPlans()` */
  static readonly MembershipPlanControllerListPublicPlansPath = '/api/membership/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerListPublicPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerListPublicPlans$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MembershipPlanDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerListPublicPlansPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MembershipPlanDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerListPublicPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerListPublicPlans(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MembershipPlanDto>> {
    return this.membershipPlanControllerListPublicPlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipPlanDto>>): Array<MembershipPlanDto> => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerCreateMembershipPlan()` */
  static readonly MembershipPlanControllerCreateMembershipPlanPath = '/api/membership/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerCreateMembershipPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerCreateMembershipPlan$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerCreateMembershipPlanPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerCreateMembershipPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerCreateMembershipPlan(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipPlanControllerCreateMembershipPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerListAddons()` */
  static readonly MembershipPlanControllerListAddonsPath = '/api/membership/plan/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerListAddons()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerListAddons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MembershipPlanAddonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerListAddonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MembershipPlanAddonDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerListAddons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerListAddons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MembershipPlanAddonDto>> {
    return this.membershipPlanControllerListAddons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipPlanAddonDto>>): Array<MembershipPlanAddonDto> => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerCreateAddons()` */
  static readonly MembershipPlanControllerCreateAddonsPath = '/api/membership/plan/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerCreateAddons()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerCreateAddons$Response(
    params: {
      body: CreateMembershipPlanAddonsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MembershipPlanAddonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerCreateAddonsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MembershipPlanAddonDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerCreateAddons$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerCreateAddons(
    params: {
      body: CreateMembershipPlanAddonsDto
    },
    context?: HttpContext
  ): Observable<Array<MembershipPlanAddonDto>> {
    return this.membershipPlanControllerCreateAddons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipPlanAddonDto>>): Array<MembershipPlanAddonDto> => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerUpdateAddon()` */
  static readonly MembershipPlanControllerUpdateAddonPath = '/api/membership/plan/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerUpdateAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerUpdateAddon$Response(
    params: {
      addonId: string;
      body: UpdateMembershipPlanAddonDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerUpdateAddonPath, 'put');
    if (params) {
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerUpdateAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerUpdateAddon(
    params: {
      addonId: string;
      body: UpdateMembershipPlanAddonDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipPlanControllerUpdateAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerDeleteAddon()` */
  static readonly MembershipPlanControllerDeleteAddonPath = '/api/membership/plan/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerDeleteAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerDeleteAddon$Response(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerDeleteAddonPath, 'delete');
    if (params) {
      rb.path('addonId', params.addonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerDeleteAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipPlanControllerDeleteAddon(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipPlanControllerDeleteAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipPlanControllerCreateCoupon()` */
  static readonly MembershipPlanControllerCreateCouponPath = '/api/membership/plan/coupon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipPlanControllerCreateCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerCreateCoupon$Response(
    params: {
      body: CreateMembershipCouponDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MembershipCouponDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipPlanApi.MembershipPlanControllerCreateCouponPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MembershipCouponDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipPlanControllerCreateCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipPlanControllerCreateCoupon(
    params: {
      body: CreateMembershipCouponDto
    },
    context?: HttpContext
  ): Observable<MembershipCouponDto> {
    return this.membershipPlanControllerCreateCoupon$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipCouponDto>): MembershipCouponDto => r.body)
    );
  }

}
