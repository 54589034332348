import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { map, tap } from 'rxjs/operators';
import { SitesService } from '../../services/sites/sites.service';
import { MembershipStatusDto, SubscriptionStatusDto } from 'src/app/api/models';
import { FeatureFlagService } from 'src/app/shared/services/feature-flag/feature-flag.service';
import { forkJoin } from 'rxjs';

const SUBSCRIPTION_EXP_SAFETY_MARGIN_HOURS = 36;

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  membershipStatus = MembershipStatusDto;
  subscriptionStatus = SubscriptionStatusDto;
  constructor(
    private userService: UserService,
    private router: Router,
    private sitesService: SitesService,
    private featureFlagService: FeatureFlagService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return forkJoin([this.userService.fetchUser(), this.featureFlagService.fetchFlags()]).pipe(
      tap(([user, flags]) => {
        const expDateWithMargin = new Date(user.subscription?.expireAt)
          .setHours(new Date(user.subscription?.expireAt).getHours() + SUBSCRIPTION_EXP_SAFETY_MARGIN_HOURS)
        const isSubscriptionValid = expDateWithMargin > Date.now();
        if (!user.subscription && user.role === 'GUEST') {
          return;
        } else if ((!user.subscription || !isSubscriptionValid) && ((user.subscription?.oldStatus !== this.subscriptionStatus.PastDue) && (user.subscription?.status !== this.membershipStatus.PastDue))) {
          if (route.routeConfig.path !== 'plans' && route.routeConfig.path !== 'thank-you') {
            return this.router.navigate(['/plans'])
          }
        } else if (user.subscription && ((user.subscription?.oldStatus === this.subscriptionStatus.Active || user.subscription?.status === this.membershipStatus.Active) || (user.subscription?.oldStatus === this.subscriptionStatus.PastDue || user.subscription?.status === this.membershipStatus.PastDue) || (user.subscription?.isTrialing && isSubscriptionValid))) {
          if (route.routeConfig.path === 'plans') {
            return this.router.navigate(['/'])
          }
        }
      }),
      map(([user, flags]) => user)
    );
  }
}