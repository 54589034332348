import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { MembershipApi, MembershipPlanApi } from '../../../api/services';
import { first, map, tap } from 'rxjs/operators';
import {
  CurrencyCodeDto,
  GetUpcomingInvoiceResponseDto,
  GetUpdatePaymentMethodCheckoutDto, ListMembershipInvoicesResponseItemDto, MembershipDto,
  MembershipPaymentsStrategy,
  MembershipPlanAddonDto,
  MembershipPlanDto,
  MembershipPlanFeaturesDto,
  MembershipPlanPaymentOptionDto, MembershipStatusDto,
  PaymentIntervalDto,
  PreviewMembershipPlanSwitchResponseDto,
  PreviewMembershipUpdateRequestDto,
  RegisterMemberhsipWithCouponRequestDto,
  SwitchMembershipPlanRequestDto
} from '../../../api/models';
import { PaddleBillingService } from '../../../signup/services/paddle-billing.service';
import { FeatureFlagService } from '../../../shared/services/feature-flag/feature-flag.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { UserService } from '../user/user.service';

export class Membership {
  id: string;
  createdAt: Date;
  user: string;
  planId: string;
  planPaymentOptionId: string;
  expireAt: Date | null;
  status: MembershipStatusDto;
  paymentsProviderCustomerId: string;

  static fromEntity(entity: MembershipDto): Required<Membership> {
    if (!entity) {
      return null;
    }
    return {
      id: entity.id,
      createdAt: new Date(entity.createdAt),
      user: entity.userId,
      planId: entity.planId,
      planPaymentOptionId: entity.planPaymentOptionId,
      status: entity.status,
      expireAt: entity.expireAt ? new Date(entity.expireAt) : null,
      paymentsProviderCustomerId: entity.paymentsProviderCustomerId,
    };
  }
}

export class MembershipPlanPaymentOption {
  id: string;
  paymentInterval: PaymentIntervalDto;
  price: number;
  currency?: CurrencyCodeDto;
  paymentsProviderPriceId: string;

  static fromMembershipPlanPaymentOptionDto(dto: MembershipPlanPaymentOptionDto | null): Required<MembershipPlanPaymentOption> | null {
    if (!dto) {
      return null;
    }

    return {
      id: dto.id,
      paymentInterval: dto.interval,
      price: dto.price,
      paymentsProviderPriceId: dto.paymentsProviderPriceId,
      currency: dto.currency as CurrencyCodeDto
    };
  }
}

export class MembershipPlan {
  id: string;
  name: string;
  providerId: string;
  description?: string;
  features: MembershipPlanFeaturesDto;
  paymentOptions: MembershipPlanPaymentOption[];
  active: boolean;
  paymentsStrategy: MembershipPaymentsStrategy;

  static fromApi(dto: MembershipPlanDto): Required<MembershipPlan> | null {
    if (!dto) {
      return null;
    }
    return {
      id: dto.id,
      name: dto.name,
      paymentOptions: dto.paymentOptions.map(option => MembershipPlanPaymentOption.fromMembershipPlanPaymentOptionDto(option)),
      providerId: dto.providerId,
      description: dto.description,
      features: dto.features,
      active: dto.active,
      paymentsStrategy: dto.paymentsStrategy
    };
  }
}

export interface BaseMembershipService {
  initializePaddle(): Promise<void>;
}
@Injectable({
  providedIn: 'root'
})
export class MembershipService implements BaseMembershipService {
  private plans: BehaviorSubject<MembershipPlan[]> = new BehaviorSubject([]);

  public readonly plans$ = this.plans.asObservable();

  // Signals
  private readonly state = {
    membership: signal<Membership | null>(null),
    isPaddleInitialized: signal<boolean>(false),
  } as const;

  public readonly membership = this.state.membership.asReadonly();
  public readonly isPaddleInitialized = this.state.isPaddleInitialized.asReadonly();

  constructor(
    private readonly paddle: PaddleBillingService,
    private membershipApi: MembershipApi,
    private membershipPlanApi: MembershipPlanApi,
    private readonly featureFlagService: FeatureFlagService,
    private readonly subscriptionService: SubscriptionService,
    // temp
    private readonly userService: UserService,
  ) {
  }

  async fetchMembership() {
    const res = await firstValueFrom(
      this.membershipApi.membershipControllerGetMembership()
    );
    this.state.membership.set(Membership.fromEntity(res));
  }

  async initializePaddle() {
    if (!this.isPaddleInitialized()) {
      if (this.featureFlagService.usePaddleBilling() || this.userService.userSnapshot?.subscription?.isTrialing) {
        await this.paddle.initialize();
      } else {
        await this.subscriptionService.initializePaddle();
      }
      this.state.isPaddleInitialized.set(true);
    }
  }

  fetchPlans(): Observable<MembershipPlan[]> {
    return this.membershipPlanApi.membershipPlanControllerListPublicPlans().pipe(
      map(plans => plans.map(plan => MembershipPlan.fromApi(plan))),
      map(plans => this.sortPlansByPrice(plans)),
      tap(plans => this.plans.next(plans)),
    );
  }

  setPlans(plans: MembershipPlan[]) {
    this.plans.next(plans);
  }

  registerFreeTrialSubscription(): Observable<void> {
    return this.membershipApi.membershipControllerRegisterFreeTrial().pipe(first());
  }

  registerWithCoupon(body: RegisterMemberhsipWithCouponRequestDto): Observable<void> {
    return this.membershipApi.membershipControllerRegisterWithCoupon({ body }).pipe(first());
  }

  listMembershipInvoices(): Observable<ListMembershipInvoicesResponseItemDto[]> {
    return this.membershipApi.membershipControllerListMembershipInvoices().pipe(
      first(),
      map(response => response.invoices)
    );
  }

  getUpcomingMembershipInvoice(): Observable<GetUpcomingInvoiceResponseDto> {
    return this.membershipApi.membershipControllerGetUpcomingInvoice().pipe(first());
  }

  previewMembershipUpdate(body: PreviewMembershipUpdateRequestDto): Observable<PreviewMembershipPlanSwitchResponseDto> {
    return this.membershipApi.membershipControllerPreviewMembershipUpdate({ body }).pipe(first());
  }

  switchMembershipPlan(body: SwitchMembershipPlanRequestDto): Observable<void> {
    return this.membershipApi.membershipControllerSwitchMembershipPlan({ body }).pipe(first());
  }

  updatePaymentMethod(): Observable<GetUpdatePaymentMethodCheckoutDto> {
    return this.membershipApi.membershipControllerGetUpdatePaymentMethodCheckout().pipe(first());
  }

  cancelMembership(): Observable<void> {
    return this.membershipApi.membershipControllerCancelMembership().pipe(first());
  }

  abortMembershipCancellation(): Observable<void> {
    return this.membershipApi.membershipControllerAbortCancellation().pipe(first());
  }

  listAddonPlans(): Observable<MembershipPlanAddonDto[]> {
    return this.membershipPlanApi.membershipPlanControllerListAddons().pipe(
      first(),
      map(plans => plans?.sort((a, b) => parseFloat(a.name.replace(/,/g, '')) - parseFloat(b.name.replace(/,/g, '')))),
    );
  }

  // CREATE/UPDATE/DELETE addons - not necessary for now?

  private sortPlansByPrice(plans: MembershipPlan[]): MembershipPlan[] {
    return ([].concat(plans) as MembershipPlan[])
      .sort((a, b) => {
        const monthlyPaymentOptionA = a.paymentOptions.find(option => option.paymentInterval === PaymentIntervalDto.Monthly);
        const monthlyPaymentOptionB = b.paymentOptions.find(option => option.paymentInterval === PaymentIntervalDto.Monthly);
        return Math.sign(monthlyPaymentOptionA?.price - monthlyPaymentOptionB?.price);
      });
  }
}
