import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SubscriptionPlanApi } from '../../api/services';
import { GoogleAnalyticsService } from './google-analytics.service';
import {initializePaddle, getPaddleInstance} from '@paddle/paddle-js';

declare var rdt: Function;
declare var plausible: Function;
@Injectable({
  providedIn: 'root'
})
export class PaddleService {
  private paddle;

  constructor(
    private subscriptionPlanApi: SubscriptionPlanApi,
    private gaService: GoogleAnalyticsService) {
  }

  async initialize() {
    await initializePaddle({
      // seller: environment.paddleVendorId,
      // @ts-ignore
      vendor: environment.paddleVendorId,
      version: 'classic',
      environment: environment.isPaddleSandbox ? 'sandbox' : 'production',
      eventCallback: (data) => {
        // @ts-ignore
        if (data.event === "Checkout.Complete") {
          // const USER_ID = JSON.parse(data.eventData.checkout.passthrough)?.['userId']
          // @ts-ignore
          (window as any).fpr('referral', { email: data.eventData.user.email, uid: data.eventData.user.id });
          this.gaService.eventEmitter("conversion", "AW-477285266/NszrCNCzhrUYEJKXy-MB");
          plausible('Upgrade');
          rdt('track', 'Purchase');
        }
      }
    });
    this.paddle = getPaddleInstance('classic');
  }

  checkout(product: number, email: string, { userId, planId }) {
    return this.paddle.Checkout.open({
      product,
      email,
      passthrough: JSON.stringify({ userId, planId }),
      success: environment.dashboardUrl + environment.paddleSuccessRoute
    });
  }

  openUpdateBilling(url: string) {
    this.paddle.Checkout.open({
      override: url,
    })
  }

  getPlans(): Observable<any[]> {
    return this.subscriptionPlanApi.subscriptionPlanControllerGetPlans();
  }

}
