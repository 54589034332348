/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddMembershipAddonDto } from '../models/add-membership-addon-dto';
import { GetUpcomingInvoiceResponseDto } from '../models/get-upcoming-invoice-response-dto';
import { GetUpdatePaymentMethodCheckoutDto } from '../models/get-update-payment-method-checkout-dto';
import { ListMembershipInvoicesResponseDto } from '../models/list-membership-invoices-response-dto';
import { MembershipAddonDto } from '../models/membership-addon-dto';
import { MembershipDto } from '../models/membership-dto';
import { PreviewMembershipPlanSwitchResponseDto } from '../models/preview-membership-plan-switch-response-dto';
import { PreviewMembershipUpdateRequestDto } from '../models/preview-membership-update-request-dto';
import { RegisterMemberhsipWithCouponRequestDto } from '../models/register-memberhsip-with-coupon-request-dto';
import { SwitchMembershipAddonDto } from '../models/switch-membership-addon-dto';
import { SwitchMembershipPlanRequestDto } from '../models/switch-membership-plan-request-dto';

@Injectable({ providedIn: 'root' })
export class MembershipApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `membershipControllerGetMembership()` */
  static readonly MembershipControllerGetMembershipPath = '/api/membership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerGetMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetMembership$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MembershipDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerGetMembershipPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MembershipDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerGetMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetMembership(
    params?: {
    },
    context?: HttpContext
  ): Observable<MembershipDto> {
    return this.membershipControllerGetMembership$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipDto>): MembershipDto => r.body)
    );
  }

  /** Path part for operation `membershipControllerRegisterFreeTrial()` */
  static readonly MembershipControllerRegisterFreeTrialPath = '/api/membership/register/free-trial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerRegisterFreeTrial()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerRegisterFreeTrial$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerRegisterFreeTrialPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerRegisterFreeTrial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerRegisterFreeTrial(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerRegisterFreeTrial$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerRegisterWithCoupon()` */
  static readonly MembershipControllerRegisterWithCouponPath = '/api/membership/register/coupon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerRegisterWithCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerRegisterWithCoupon$Response(
    params: {
      body: RegisterMemberhsipWithCouponRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerRegisterWithCouponPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerRegisterWithCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerRegisterWithCoupon(
    params: {
      body: RegisterMemberhsipWithCouponRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerRegisterWithCoupon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerPreviewMembershipUpdate()` */
  static readonly MembershipControllerPreviewMembershipUpdatePath = '/api/membership/preview-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerPreviewMembershipUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerPreviewMembershipUpdate$Response(
    params: {
      body: PreviewMembershipUpdateRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PreviewMembershipPlanSwitchResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerPreviewMembershipUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreviewMembershipPlanSwitchResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerPreviewMembershipUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerPreviewMembershipUpdate(
    params: {
      body: PreviewMembershipUpdateRequestDto
    },
    context?: HttpContext
  ): Observable<PreviewMembershipPlanSwitchResponseDto> {
    return this.membershipControllerPreviewMembershipUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreviewMembershipPlanSwitchResponseDto>): PreviewMembershipPlanSwitchResponseDto => r.body)
    );
  }

  /** Path part for operation `membershipControllerListMembershipInvoices()` */
  static readonly MembershipControllerListMembershipInvoicesPath = '/api/membership/invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerListMembershipInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerListMembershipInvoices$Response(
    params?: {
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListMembershipInvoicesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerListMembershipInvoicesPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('lastItemId', params.lastItemId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListMembershipInvoicesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerListMembershipInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerListMembershipInvoices(
    params?: {
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<ListMembershipInvoicesResponseDto> {
    return this.membershipControllerListMembershipInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListMembershipInvoicesResponseDto>): ListMembershipInvoicesResponseDto => r.body)
    );
  }

  /** Path part for operation `membershipControllerGetUpcomingInvoice()` */
  static readonly MembershipControllerGetUpcomingInvoicePath = '/api/membership/upcoming-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerGetUpcomingInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetUpcomingInvoice$Response(
    params?: {
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetUpcomingInvoiceResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerGetUpcomingInvoicePath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('lastItemId', params.lastItemId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUpcomingInvoiceResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerGetUpcomingInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetUpcomingInvoice(
    params?: {
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<GetUpcomingInvoiceResponseDto> {
    return this.membershipControllerGetUpcomingInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUpcomingInvoiceResponseDto>): GetUpcomingInvoiceResponseDto => r.body)
    );
  }

  /** Path part for operation `membershipControllerCancelMembership()` */
  static readonly MembershipControllerCancelMembershipPath = '/api/membership/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerCancelMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerCancelMembership$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerCancelMembershipPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerCancelMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerCancelMembership(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerCancelMembership$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerAbortCancellation()` */
  static readonly MembershipControllerAbortCancellationPath = '/api/membership/abort-cancellation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerAbortCancellation()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerAbortCancellation$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerAbortCancellationPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerAbortCancellation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerAbortCancellation(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerAbortCancellation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerSwitchMembershipPlan()` */
  static readonly MembershipControllerSwitchMembershipPlanPath = '/api/membership/switch-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerSwitchMembershipPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerSwitchMembershipPlan$Response(
    params: {
      body: SwitchMembershipPlanRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerSwitchMembershipPlanPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerSwitchMembershipPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerSwitchMembershipPlan(
    params: {
      body: SwitchMembershipPlanRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerSwitchMembershipPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerGetUpdatePaymentMethodCheckout()` */
  static readonly MembershipControllerGetUpdatePaymentMethodCheckoutPath = '/api/membership/update-billing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerGetUpdatePaymentMethodCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetUpdatePaymentMethodCheckout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetUpdatePaymentMethodCheckoutDto>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerGetUpdatePaymentMethodCheckoutPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUpdatePaymentMethodCheckoutDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerGetUpdatePaymentMethodCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerGetUpdatePaymentMethodCheckout(
    params?: {
    },
    context?: HttpContext
  ): Observable<GetUpdatePaymentMethodCheckoutDto> {
    return this.membershipControllerGetUpdatePaymentMethodCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUpdatePaymentMethodCheckoutDto>): GetUpdatePaymentMethodCheckoutDto => r.body)
    );
  }

  /** Path part for operation `membershipControllerListAddons()` */
  static readonly MembershipControllerListAddonsPath = '/api/membership/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerListAddons()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerListAddons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MembershipAddonDto>>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerListAddonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MembershipAddonDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerListAddons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerListAddons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MembershipAddonDto>> {
    return this.membershipControllerListAddons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipAddonDto>>): Array<MembershipAddonDto> => r.body)
    );
  }

  /** Path part for operation `membershipControllerAddAddon()` */
  static readonly MembershipControllerAddAddonPath = '/api/membership/addon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerAddAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerAddAddon$Response(
    params: {
      body: AddMembershipAddonDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerAddAddonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerAddAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerAddAddon(
    params: {
      body: AddMembershipAddonDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerAddAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerRemoveSubscriptionAddon()` */
  static readonly MembershipControllerRemoveSubscriptionAddonPath = '/api/membership/addon/{addonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerRemoveSubscriptionAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerRemoveSubscriptionAddon$Response(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerRemoveSubscriptionAddonPath, 'delete');
    if (params) {
      rb.path('addonId', params.addonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerRemoveSubscriptionAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerRemoveSubscriptionAddon(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerRemoveSubscriptionAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerSwitchAddon()` */
  static readonly MembershipControllerSwitchAddonPath = '/api/membership/addon/{addonId}/switch-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerSwitchAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerSwitchAddon$Response(
    params: {
      addonId: string;
      body: SwitchMembershipAddonDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerSwitchAddonPath, 'post');
    if (params) {
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerSwitchAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  membershipControllerSwitchAddon(
    params: {
      addonId: string;
      body: SwitchMembershipAddonDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerSwitchAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `membershipControllerAbortAddonCancellation()` */
  static readonly MembershipControllerAbortAddonCancellationPath = '/api/membership/addon/{addonId}/abort-cancellation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `membershipControllerAbortAddonCancellation()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerAbortAddonCancellation$Response(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MembershipApi.MembershipControllerAbortAddonCancellationPath, 'post');
    if (params) {
      rb.path('addonId', params.addonId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `membershipControllerAbortAddonCancellation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  membershipControllerAbortAddonCancellation(
    params: {
      addonId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.membershipControllerAbortAddonCancellation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
