/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateUpdateBillingCheckoutLink } from '../models/create-update-billing-checkout-link';
import { ListAvailableMembershipPlansResponseDto } from '../models/list-available-membership-plans-response-dto';
import { ListWebsiteMembershipInvoicesResponseDto } from '../models/list-website-membership-invoices-response-dto';
import { ListWebsiteMembershipInvoicesResponseItemDto } from '../models/list-website-membership-invoices-response-item-dto';
import { PreviewWebsiteMembershipPlanSwitchResponseDto } from '../models/preview-website-membership-plan-switch-response-dto';
import { RegisterMembershipResponseDto } from '../models/register-membership-response-dto';
import { RegisterWebsiteMembershipDto } from '../models/register-website-membership-dto';
import { SwitchWebsiteMembershipPlanRequestDto } from '../models/switch-website-membership-plan-request-dto';
import { WebsiteMembershipDto } from '../models/website-membership-dto';

@Injectable({ providedIn: 'root' })
export class WebsitePublicMembershipApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `websiteMembershipPublicControllerGetUserMembership()` */
  static readonly WebsiteMembershipPublicControllerGetUserMembershipPath = '/api/website-public/membership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerGetUserMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerGetUserMembership$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WebsiteMembershipDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerGetUserMembershipPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WebsiteMembershipDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerGetUserMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerGetUserMembership(
    params?: {
    },
    context?: HttpContext
  ): Observable<WebsiteMembershipDto> {
    return this.websiteMembershipPublicControllerGetUserMembership$Response(params, context).pipe(
      map((r: StrictHttpResponse<WebsiteMembershipDto>): WebsiteMembershipDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerListAvailablePlans()` */
  static readonly WebsiteMembershipPublicControllerListAvailablePlansPath = '/api/website-public/membership/plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerListAvailablePlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerListAvailablePlans$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListAvailableMembershipPlansResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerListAvailablePlansPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListAvailableMembershipPlansResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerListAvailablePlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerListAvailablePlans(
    params?: {
    },
    context?: HttpContext
  ): Observable<ListAvailableMembershipPlansResponseDto> {
    return this.websiteMembershipPublicControllerListAvailablePlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListAvailableMembershipPlansResponseDto>): ListAvailableMembershipPlansResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerRegister()` */
  static readonly WebsiteMembershipPublicControllerRegisterPath = '/api/website-public/membership/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerRegister$Response(
    params: {
      body: RegisterWebsiteMembershipDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegisterMembershipResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerRegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterMembershipResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerRegister(
    params: {
      body: RegisterWebsiteMembershipDto
    },
    context?: HttpContext
  ): Observable<RegisterMembershipResponseDto> {
    return this.websiteMembershipPublicControllerRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegisterMembershipResponseDto>): RegisterMembershipResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerCancelMembership()` */
  static readonly WebsiteMembershipPublicControllerCancelMembershipPath = '/api/website-public/membership/{membershipId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerCancelMembership()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerCancelMembership$Response(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerCancelMembershipPath, 'post');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerCancelMembership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerCancelMembership(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPublicControllerCancelMembership$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerSwitchMembershipPlan()` */
  static readonly WebsiteMembershipPublicControllerSwitchMembershipPlanPath = '/api/website-public/membership/{membershipId}/switch-plan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerSwitchMembershipPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerSwitchMembershipPlan$Response(
    params: {
      membershipId: string;
      body: SwitchWebsiteMembershipPlanRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerSwitchMembershipPlanPath, 'post');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerSwitchMembershipPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerSwitchMembershipPlan(
    params: {
      membershipId: string;
      body: SwitchWebsiteMembershipPlanRequestDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPublicControllerSwitchMembershipPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerSwitchMembershipPlanPreview()` */
  static readonly WebsiteMembershipPublicControllerSwitchMembershipPlanPreviewPath = '/api/website-public/membership/{membershipId}/switch-plan/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerSwitchMembershipPlanPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerSwitchMembershipPlanPreview$Response(
    params: {
      membershipId: string;
      planId: string;
      paymentOptionId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PreviewWebsiteMembershipPlanSwitchResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerSwitchMembershipPlanPreviewPath, 'get');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
      rb.query('planId', params.planId, {});
      rb.query('paymentOptionId', params.paymentOptionId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PreviewWebsiteMembershipPlanSwitchResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerSwitchMembershipPlanPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerSwitchMembershipPlanPreview(
    params: {
      membershipId: string;
      planId: string;
      paymentOptionId: string;
    },
    context?: HttpContext
  ): Observable<PreviewWebsiteMembershipPlanSwitchResponseDto> {
    return this.websiteMembershipPublicControllerSwitchMembershipPlanPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<PreviewWebsiteMembershipPlanSwitchResponseDto>): PreviewWebsiteMembershipPlanSwitchResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerListMembershipInvoices()` */
  static readonly WebsiteMembershipPublicControllerListMembershipInvoicesPath = '/api/website-public/membership/{membershipId}/invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerListMembershipInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerListMembershipInvoices$Response(
    params: {
      membershipId: string;
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListWebsiteMembershipInvoicesResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerListMembershipInvoicesPath, 'get');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
      rb.query('limit', params.limit, {});
      rb.query('lastItemId', params.lastItemId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWebsiteMembershipInvoicesResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerListMembershipInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerListMembershipInvoices(
    params: {
      membershipId: string;
      limit?: number;
      lastItemId?: string;
    },
    context?: HttpContext
  ): Observable<ListWebsiteMembershipInvoicesResponseDto> {
    return this.websiteMembershipPublicControllerListMembershipInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListWebsiteMembershipInvoicesResponseDto>): ListWebsiteMembershipInvoicesResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerCreateUpdateBillingMembershipLink()` */
  static readonly WebsiteMembershipPublicControllerCreateUpdateBillingMembershipLinkPath = '/api/website-public/membership/{membershipId}/checkout-link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerCreateUpdateBillingMembershipLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerCreateUpdateBillingMembershipLink$Response(
    params: {
      membershipId: string;
      body: CreateUpdateBillingCheckoutLink
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegisterMembershipResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerCreateUpdateBillingMembershipLinkPath, 'post');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterMembershipResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerCreateUpdateBillingMembershipLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  websiteMembershipPublicControllerCreateUpdateBillingMembershipLink(
    params: {
      membershipId: string;
      body: CreateUpdateBillingCheckoutLink
    },
    context?: HttpContext
  ): Observable<RegisterMembershipResponseDto> {
    return this.websiteMembershipPublicControllerCreateUpdateBillingMembershipLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegisterMembershipResponseDto>): RegisterMembershipResponseDto => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerAbortScheduledCancellation()` */
  static readonly WebsiteMembershipPublicControllerAbortScheduledCancellationPath = '/api/website-public/membership/{membershipId}/abort-cancellation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerAbortScheduledCancellation()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerAbortScheduledCancellation$Response(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerAbortScheduledCancellationPath, 'post');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerAbortScheduledCancellation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerAbortScheduledCancellation(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.websiteMembershipPublicControllerAbortScheduledCancellation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `websiteMembershipPublicControllerGetUpcomingInvoice()` */
  static readonly WebsiteMembershipPublicControllerGetUpcomingInvoicePath = '/api/website-public/membership/{membershipId}/upcoming-invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websiteMembershipPublicControllerGetUpcomingInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerGetUpcomingInvoice$Response(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ListWebsiteMembershipInvoicesResponseItemDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebsitePublicMembershipApi.WebsiteMembershipPublicControllerGetUpcomingInvoicePath, 'get');
    if (params) {
      rb.path('membershipId', params.membershipId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListWebsiteMembershipInvoicesResponseItemDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websiteMembershipPublicControllerGetUpcomingInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websiteMembershipPublicControllerGetUpcomingInvoice(
    params: {
      membershipId: string;
    },
    context?: HttpContext
  ): Observable<ListWebsiteMembershipInvoicesResponseItemDto> {
    return this.websiteMembershipPublicControllerGetUpcomingInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListWebsiteMembershipInvoicesResponseItemDto>): ListWebsiteMembershipInvoicesResponseItemDto => r.body)
    );
  }

}
